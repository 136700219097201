body {
  background-attachment: fixed !important;
  background: linear-gradient(180deg, rgba(225, 244, 252, 0.5) 0%, rgba(122, 194, 235, 0.5) 57.86%, rgba(110, 188, 238, 0.5) 64.58%, rgba(75, 133, 196, 0.5) 100%), #FFFFFF;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}
.input:focus {
  outline: none !important;
  border: none;
}

.css-1f2qhs8 {
  white-space: pre-wrap !important;
}